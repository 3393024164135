import { createElement } from 'react';

const EnvelopeIcon = (props) => (createElement("svg", Object.assign({ xmlns: "http://www.w3.org/2000/svg", width: "24", height: "24", viewBox: "0 0 24 24" }, props),
    createElement("title", null, "Envelope icon"),
    createElement("g", { fill: "#A4AAA8", fillRule: "nonzero" },
        createElement("path", { d: "M18.5 18a.5.5 0 0 0 .5-.5v-10a.5.5 0 0 0-.5-.5h-13a.5.5 0 0 0-.5.5v10a.5.5 0 0 0 .5.5h13zm0 1h-13A1.5 1.5 0 0 1 4 17.5v-10A1.5 1.5 0 0 1 5.5 6h13A1.5 1.5 0 0 1 20 7.5v10a1.5 1.5 0 0 1-1.5 1.5z" }),
        createElement("path", { d: "M17.183 9.113a.5.5 0 1 1 .634.774l-5.5 4.5a.5.5 0 0 1-.634 0l-5.5-4.5a.5.5 0 0 1 .634-.774L12 13.354l5.183-4.241zM6.854 15.854a.5.5 0 0 1-.708-.708l1.5-1.5a.5.5 0 1 1 .708.708l-1.5 1.5zM17.854 15.146a.5.5 0 0 1-.708.708l-1.5-1.5a.5.5 0 0 1 .708-.708l1.5 1.5z" }))));
EnvelopeIcon.defaultProps = {
    "data-testid": "envelope-icon",
};

export default EnvelopeIcon;
export { EnvelopeIcon };
