import React__default from 'react';
import 'styled-components';
import { XolvioLogo } from '../Icons/XolvioLogo.js';

const defaultHeaderProps = {
    animate: false,
    menuOpen: false,
    simpleMenu: false,
    logo: (React__default.createElement(XolvioLogo, { onClick: () => (window.location.href = "/"), style: { cursor: "pointer" } })),
    items: [
        {
            name: "Home",
            url: "/",
        },
        {
            name: "About Us",
            url: "/about-us/",
        },
        {
            name: "Services",
            url: "/services/",
        },
        {
            name: "Products",
            url: "/products/",
        },
        {
            name: "Blog",
            url: "/blog/",
            sublinks: [
                {
                    name: "Articles",
                    url: "/blog/articles/",
                },
                {
                    name: "Dev Notes",
                    url: "/blog/dev-notes/",
                },
            ],
        },
    ],
    primaryButtonText: "Request consultation",
    primaryButtonUrl: "/contact-us",
    socialIcons: {
        twitter: "https://twitter.com/xolvio",
        linkedin: "https://www.linkedin.com/company/xolvio/",
    },
};

export { defaultHeaderProps };
