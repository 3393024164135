import { createElement } from 'react';

const StopwatchSmallIcon = (props) => (createElement("svg", Object.assign({ xmlns: "http://www.w3.org/2000/svg", width: "64", height: "64", viewBox: "0 0 64 64" }, props),
    createElement("title", null, "Stopwatch icon"),
    createElement("g", { fill: "none", fillRule: "evenodd" },
        createElement("path", { d: "M0 0h64v64H0z" }),
        createElement("path", { fill: "#BAE1D3", d: "M58 34.542C58 18.78 45.464 6 30 6v28.542L45.945 58C53.228 52.846 58 44.265 58 34.542" }),
        createElement("path", { fill: "#323433", fillRule: "nonzero", d: "M6 34c0 13.255 10.745 24 24 24s24-10.745 24-24-10.745-24-24-24S6 20.745 6 34zm2 0c0-12.15 9.85-22 22-22s22 9.85 22 22-9.85 22-22 22S8 46.15 8 34z" }),
        createElement("path", { fill: "#323433", fillRule: "nonzero", d: "M19.364 17.18C14.173 20.8 11 26.832 11 33.412 11 44.224 19.499 53 30 53v-2c-9.382 0-17-7.867-17-17.588 0-5.923 2.851-11.343 7.508-14.592l-1.144-1.64zM39.794 49.853C45.462 46.382 49 40.153 49 33.306 49 22.647 40.497 14 30 14v2c9.385 0 17 7.745 17 17.306 0 6.147-3.172 11.731-8.25 14.841l1.044 1.706z" }),
        createElement("path", { fill: "#FFF", d: "M27 33.5a3.5 3.5 0 1 0 7 0 3.5 3.5 0 0 0-7 0" }),
        createElement("path", { fill: "#323433", fillRule: "nonzero", d: "M26 33.5a4.5 4.5 0 1 0 9 0 4.5 4.5 0 0 0-9 0zm2 0a2.5 2.5 0 1 1 5 0 2.5 2.5 0 0 1-5 0z" }),
        createElement("path", { fill: "#323433", fillRule: "nonzero", d: "M31.168 37.555l6 9 1.664-1.11-6-9z" }))));
StopwatchSmallIcon.defaultProps = {
    "data-testid": "icon-stopwatch-small",
};

export default StopwatchSmallIcon;
export { StopwatchSmallIcon };
