import React__default from 'react';

const Confetti7Rows = (props) => (React__default.createElement("svg", Object.assign({ "data-testid": props["data-testid"], xmlns: "http://www.w3.org/2000/svg", width: "204", height: "143", viewBox: "0 0 204 143" }, props),
    React__default.createElement("title", null, "Confetti pattern"),
    React__default.createElement("g", { fill: "#56BF98", fillRule: "evenodd" },
        React__default.createElement("path", { d: "M0 44l9.857 6.838v2.632L0 46.65V44zm0 21.952l9.857 6.838v2.632L0 68.603v-2.65zM24 44l9.857 6.838v2.632L24 46.65V44zm24 0l9.857 6.838v2.632L48 46.65V44zm24.429 0l9.857 6.838v2.632l-9.857-6.819V44zm0 21.952l9.857 6.838v2.632l-9.857-6.819v-2.65zm24-21.952l9.857 6.838v2.632l-9.857-6.819V44zm0 43.904l9.857 6.838v2.632l-9.857-6.818v-2.652zM120.857 44l9.857 6.838v2.632l-9.857-6.819V44zm48.429 0l9.857 6.838v2.632l-9.857-6.819V44zm-24.429 21.952l9.857 6.838v2.632l-9.857-6.819v-2.65zm-25.286 0l9.858 6.838v2.632l-9.858-6.819v-2.65zM24 88.335l9.857 6.838v2.631L24 90.986v-2.651zm0-21.952l9.857 6.837v2.632L24 69.034v-2.651zm24 21.952l9.857 6.838v2.631L48 90.986v-2.651zm24.429 0l9.857 6.838v2.631l-9.857-6.818v-2.651zm48.428 0l9.857 6.838v2.631l-9.857-6.818v-2.651zm24 0l9.857 6.838v2.631l-9.857-6.818v-2.651zm24.429 0l9.857 6.838v2.631l-9.857-6.818v-2.651zM24 110.717l9.857 6.838v2.632L24 113.369v-2.652zm-24 0l9.857 6.838v2.632L0 113.369v-2.652zm0 22.813l9.857 6.838V143L0 136.182v-2.652zm48-22.813l9.857 6.838v2.632L48 113.369v-2.652zm0 22.813l9.857 6.838V143L48 136.182v-2.652zm24.429-.86l9.857 6.837v2.632l-9.857-6.818v-2.651zm24-21.953l9.857 6.838v2.632l-9.857-6.818v-2.652zm76.928-41.537h3v3.013h-3V69.18zM51.214 70.902h3v3.013h-3v-3.013zm96 42.613h3v3.013h-3v-3.013zM27.643 137.19h3v3.013h-3v-3.013zm68.786-4.52l9.857 6.838v2.632l-9.857-6.818v-2.651zm24.428-21.952l9.857 6.838v2.632l-9.857-6.818v-2.652zm0 21.953l9.857 6.837v2.632l-9.857-6.818v-2.651zm24 0l9.857 6.837v2.632l-9.857-6.818v-2.651zm24.429-21.953l9.857 6.838v2.632l-9.857-6.818v-2.652zM194.143 44L204 50.838v2.632l-9.857-6.819V44zm0 44.335L204 95.173v2.631l-9.857-6.818v-2.651zm0 22.382l9.857 6.838v2.632l-9.857-6.818v-2.652zm0 21.953l9.857 6.837v2.632l-9.857-6.818v-2.651z" }),
        React__default.createElement("path", { d: "M96.429 65.921l9.857 6.828v2.628l-9.857-6.808V65.92zM0 87.842l9.857 6.828v2.628L0 90.49v-2.648zM144.857 44l9.857 6.828v2.628l-9.857-6.808V44zM72.43 110.623l9.857 6.828v2.628l-9.857-6.809v-2.647zm96.857 21.92l9.857 6.829V142l-9.857-6.809v-2.647zm24.857-66.622L204 72.75v2.628l-9.857-6.808V65.92z", opacity: ".297" }),
        React__default.createElement("g", null,
            React__default.createElement("path", { d: "M24 .717l9.857 6.838v2.632L24 3.369V.717zm-24 0l9.857 6.838v2.632L0 3.369V.717zM0 23.53l9.857 6.838V33L0 26.182V23.53zM48 .717l9.857 6.838v2.632L48 3.369V.717zm0 22.813l9.857 6.838V33L48 26.182V23.53zm24.429-.86l9.857 6.837v2.632l-9.857-6.818V22.67zm24-21.953l9.857 6.838v2.632l-9.857-6.818V.717zm50.785 2.798h3v3.013h-3V3.515zM27.643 27.19h3v3.013h-3V27.19zm68.786-4.52l9.857 6.838v2.632l-9.857-6.818V22.67zM120.857.718l9.857 6.838v2.632l-9.857-6.818V.717zm0 21.953l9.857 6.837v2.632l-9.857-6.818V22.67zm24 0l9.857 6.837v2.632l-9.857-6.818V22.67zM169.286.717l9.857 6.838v2.632l-9.857-6.818V.717zm24.857 0L204 7.555v2.632l-9.857-6.818V.717zm0 21.953L204 29.507v2.632l-9.857-6.818V22.67z" }),
            React__default.createElement("path", { d: "M72.429.623l9.857 6.828v2.628L72.429 3.27V.623zm96.857 21.92l9.857 6.829V32l-9.857-6.809v-2.647z", opacity: ".297" })))));
Confetti7Rows.defaultProps = {
    "data-testid": "confetti-big",
};

export { Confetti7Rows };
