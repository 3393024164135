import React__default from 'react';

const Confetti5Rows = (props) => (React__default.createElement("svg", { "data-testid": props["data-testid"], xmlns: "http://www.w3.org/2000/svg", width: "180", height: "99", viewBox: "0 0 180 99", style: props.style },
    React__default.createElement("title", null, "Confetti pattern"),
    React__default.createElement("g", { fill: "#56BF98", fillRule: "evenodd" },
        React__default.createElement("path", { d: "M0 0l9.857 6.838V9.47L0 2.65V0zm24 0l9.857 6.838V9.47L24 2.65V0zm24.429 0l9.857 6.838V9.47L48.429 2.65V0zm0 21.952l9.857 6.838v2.632l-9.857-6.819v-2.65zM72.429 0l9.857 6.838V9.47L72.429 2.65V0zm0 43.904l9.857 6.838v2.632l-9.857-6.818v-2.652zM96.857 0l9.857 6.838V9.47L96.857 2.65V0zm48.429 0l9.857 6.838V9.47l-9.857-6.819V0zm-24.429 21.952l9.857 6.838v2.632l-9.857-6.819v-2.65zm-25.286 0l9.858 6.838v2.632l-9.858-6.819v-2.65zM0 44.335l9.857 6.838v2.631L0 46.986v-2.651zm0-21.952l9.857 6.837v2.632L0 25.034v-2.651zm24 21.952l9.857 6.838v2.631L24 46.986v-2.651zm24.429 0l9.857 6.838v2.631l-9.857-6.818v-2.651zm48.428 0l9.857 6.838v2.631l-9.857-6.818v-2.651zm24 0l9.857 6.838v2.631l-9.857-6.818v-2.651zm24.429 0l9.857 6.838v2.631l-9.857-6.818v-2.651zM0 66.717l9.857 6.838v2.632L0 69.369v-2.652zm24 0l9.857 6.838v2.632L24 69.369v-2.652zm0 22.813l9.857 6.838V99L24 92.182V89.53zm24.429-.86l9.857 6.837v2.632l-9.857-6.818V88.67zm24-21.953l9.857 6.838v2.632l-9.857-6.818v-2.652zm76.928-41.537h3v3.013h-3V25.18zM27.214 26.902h3v3.013h-3v-3.013zm96 42.613h3v3.013h-3v-3.013zM3.643 93.19h3v3.013h-3V93.19zm68.786-4.52l9.857 6.838v2.632l-9.857-6.818V88.67zm24.428-21.952l9.857 6.838v2.632l-9.857-6.818v-2.652zm0 21.953l9.857 6.837v2.632l-9.857-6.818V88.67zm24 0l9.857 6.837v2.632l-9.857-6.818V88.67zm24.429-21.953l9.857 6.838v2.632l-9.857-6.818v-2.652zM170.143 0L180 6.838V9.47l-9.857-6.819V0zm0 44.335L180 51.173v2.631l-9.857-6.818v-2.651zm0 22.382L180 73.555v2.632l-9.857-6.818v-2.652zm0 21.953L180 95.507v2.632l-9.857-6.818V88.67z" }),
        React__default.createElement("path", { d: "M72.429 21.921l9.857 6.828v2.628l-9.857-6.808V21.92zM120.857 0l9.857 6.828v2.628l-9.857-6.808V0zM48.43 66.623l9.857 6.828v2.628l-9.857-6.809v-2.647zm96.857 21.92l9.857 6.829V98l-9.857-6.809v-2.647zm24.857-66.622L180 28.75v2.628l-9.857-6.808V21.92z", opacity: ".297" }))));
Confetti5Rows.defaultProps = {
    "data-testid": "confetti",
};

export { Confetti5Rows };
