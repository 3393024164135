import React__default from 'react';

const CheckIconThin = (props) => (React__default.createElement("svg", Object.assign({ "data-testid": props["data-testid"], xmlns: "http://www.w3.org/2000/svg", width: "24", height: "24", viewBox: "0 0 24 24" }, props),
    React__default.createElement("title", null, "Check icon"),
    React__default.createElement("path", { fill: "#56BF98", fillRule: "nonzero", d: "M19.146 7.146a.5.5 0 0 1 .708.708l-10 10a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 .708-.708L9.5 16.793l9.646-9.647z" })));
CheckIconThin.defaultProps = {
    "data-testid": "check-icon-thin",
};

export { CheckIconThin };
