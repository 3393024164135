import React__default from 'react';

const QuoteIcon = (props) => (React__default.createElement("svg", Object.assign({ xmlns: "http://www.w3.org/2000/svg", width: "64", height: "64", viewBox: "0 0 64 64" }, props),
    React__default.createElement("title", null, "Quote icon"),
    React__default.createElement("g", { fill: "none", fillRule: "evenodd" },
        React__default.createElement("path", { d: "M0 0h64v64H0z" }),
        React__default.createElement("path", { fill: "#BAE1D3", d: "M13 32h20V13H13zM40 32h20V13H40z" }),
        React__default.createElement("path", { fill: "#323433", fillRule: "nonzero", d: "M19.7 38.816c-.864 1.92-3.826 3.137-10.132 3.137h-1V53h1c9.28 0 15.348-3.134 18.726-8.375a17.305 17.305 0 0 0 2.365-5.765c.255-1.189.341-2.127.341-2.693V16H8v21.167h12.005c-.004.695-.083 1.156-.305 1.65zM10 18h19v18.167c0 .098-.009.302-.036.597-.046.503-.13 1.067-.26 1.676a15.324 15.324 0 0 1-2.091 5.102c-2.883 4.472-8.006 7.235-16.045 7.445v-7.045c6.36-.14 9.757-1.64 10.956-4.305.433-.963.514-1.769.47-3.062a11.15 11.15 0 0 1-.01-.408v-1H10V18zM47.005 37.167c-.004.695-.083 1.156-.305 1.65-.864 1.92-3.826 3.136-10.132 3.136h-1V53h1c9.28 0 15.348-3.134 18.726-8.375a17.305 17.305 0 0 0 2.365-5.765c.255-1.189.341-2.127.341-2.693V16H35v21.167h12.005zM37 18h19v18.167c0 .098-.009.302-.036.597-.046.503-.13 1.067-.26 1.676a15.324 15.324 0 0 1-2.091 5.102c-2.883 4.472-8.006 7.235-16.045 7.445v-7.045c6.36-.14 9.757-1.64 10.956-4.305.433-.963.514-1.769.47-3.062a11.15 11.15 0 0 1-.01-.408v-1H37V18z" }))));
QuoteIcon.defaultProps = {
    "data-testid": "icon-quote",
};

export { QuoteIcon };
