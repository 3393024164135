import { createElement } from 'react';

const MediumIcon = (props) => (createElement("svg", Object.assign({ xmlns: "http://www.w3.org/2000/svg", width: "24", height: "24", viewBox: "0 0 24 24" }, props),
    createElement("title", null, "Medium icon"),
    createElement("path", { fill: "#A4AAA8", fillRule: "nonzero", d: "M23 0H1a1 1 0 0 0-1 1v22a1 1 0 0 0 1 1h22a1 1 0 0 0 1-1V1a1 1 0 0 0-1-1zm-3.062 5.686L18.651 6.92a.376.376 0 0 0-.143.361v9.068a.376.376 0 0 0 .143.361l1.257 1.234v.271h-6.322v-.271l1.3-1.264c.128-.128.128-.166.128-.361V8.99l-3.621 9.2h-.489l-4.213-9.2v6.163a.85.85 0 0 0 .233.707l1.694 2.054v.271h-4.8v-.271l1.691-2.054a.82.82 0 0 0 .218-.707V8.027a.625.625 0 0 0-.2-.527L4.019 5.686v-.271h4.674l3.613 7.924 3.176-7.924h4.456v.271z" })));
MediumIcon.defaultProps = {
    "data-testid": "medium-icon",
};

export default MediumIcon;
export { MediumIcon };
