import React__default from 'react';

const Confetti5x9 = (props) => (React__default.createElement("svg", Object.assign({ xmlns: "http://www.w3.org/2000/svg", width: "204", height: "98", viewBox: "0 0 204 98" }, props),
    React__default.createElement("title", null, "Confetti pattern"),
    React__default.createElement("g", { fill: "#56BF98", fillRule: "evenodd" },
        React__default.createElement("path", { d: "M.5.5l10 6.499V9.5L.5 3.02zM.5 22l10 6.499V31l-10-6.48zM24.5.5l10 6.499V9.5l-10-6.48zM48.5.5l10 6.499V9.5l-10-6.48zM72.5.5l10 6.499V9.5l-10-6.48zM72.5 22l10 6.499V31l-10-6.48zM96.5.5l10 6.499V9.5l-10-6.48z" }),
        React__default.createElement("path", { d: "M96.5 22l10 6.499V31l-10-6.48z", opacity: ".297" }),
        React__default.createElement("path", { d: "M96.5 43.5l10 6.499V52.5l-10-6.48z" }),
        React__default.createElement("path", { d: "M.5 43.5l10 6.499V52.5l-10-6.48z", opacity: ".288" }),
        React__default.createElement("path", { d: "M120.5.5l10 6.499V9.5l-10-6.48z" }),
        React__default.createElement("path", { d: "M144.5.5l10 6.499V9.5l-10-6.48z", opacity: ".297" }),
        React__default.createElement("path", { d: "M169 .5l10 6.499V9.5l-10-6.48zM144.5 22l10 6.499V31l-10-6.48zM119.5 22l10 6.499V31l-10-6.48zM24.5 44l10 6.499V53l-10-6.48zM24.5 22.5l10 6.499V31.5l-10-6.48zM48.5 44l10 6.499V53l-10-6.48zM72.5 44l10 6.499V53l-10-6.48zM120.5 44l10 6.499V53l-10-6.48zM144.5 44l10 6.499V53l-10-6.48zM169 44l10 6.499V53l-10-6.48zM24.5 66l10 6.499V75l-10-6.48zM.5 66l10 6.499V75l-10-6.48zM.5 88.5l10 6.499V97.5l-10-6.48zM48.5 66l10 6.499V75l-10-6.48zM48.5 88.5l10 6.499V97.5l-10-6.48z" }),
        React__default.createElement("path", { d: "M72.5 66l10 6.499V75l-10-6.48z", opacity: ".314" }),
        React__default.createElement("path", { d: "M72.5 88l10 6.499V97l-10-6.48zM96.5 66l10 6.499V75l-10-6.48zM173 25h3v3h-3zM52 27h3v3h-3zM147 69h3v3h-3zM28 92h3v3h-3zM96.5 88l10 6.499V97l-10-6.48zM120.5 66l10 6.499V75l-10-6.48zM120.5 88l10 6.499V97l-10-6.48zM144.5 88l10 6.499V97l-10-6.48zM169 66l10 6.499V75l-10-6.48z" }),
        React__default.createElement("path", { d: "M169 88l10 6.499V97l-10-6.48z", opacity: ".314" }),
        React__default.createElement("path", { d: "M194 .5l10 6.499V9.5l-10-6.48z" }),
        React__default.createElement("path", { d: "M194 22l10 6.499V31l-10-6.48z", opacity: ".314" }),
        React__default.createElement("path", { d: "M194 44l10 6.499V53l-10-6.48zM194 66l10 6.499V75l-10-6.48zM194 88l10 6.499V97l-10-6.48z" }))));
Confetti5x9.defaultProps = {
    "data-testid": "confetti-micro",
};

export { Confetti5x9 };
