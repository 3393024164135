import { createElement } from 'react';

const UserIcon = (props) => (createElement("svg", Object.assign({ xmlns: "http://www.w3.org/2000/svg", width: "24", height: "24", viewBox: "0 0 24 24" }, props),
    createElement("title", null, "User icon"),
    createElement("g", { fill: "none", fillRule: "evenodd" },
        createElement("path", { fill: "#FFF", d: "M-3090-701H814v5499h-3904z" }),
        createElement("rect", { width: "326", height: "47", x: ".5", y: ".5", stroke: "#D8DCDB", rx: "2", transform: "translate(-12 -12)" }),
        createElement("g", { fill: "#A4AAA8", fillRule: "nonzero" },
            createElement("path", { d: "M13.5 14a5.5 5.5 0 0 1 5.5 5.5.5.5 0 0 1-.5.5h-13a.5.5 0 0 1-.5-.5 5.5 5.5 0 0 1 5.5-5.5h3zm3.182 2.318A4.5 4.5 0 0 0 13.5 15h-3a4.5 4.5 0 0 0-4.473 4h11.945a4.5 4.5 0 0 0-1.29-2.682zM12 12a4 4 0 1 1 0-8 4 4 0 0 1 0 8zm0-1a3 3 0 1 0 0-6 3 3 0 0 0 0 6z" })))));
UserIcon.defaultProps = {
    "data-testid": "icon-user",
};

export default UserIcon;
export { UserIcon };
