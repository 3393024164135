import React__default from 'react';

const CheckIcon = (props) => (React__default.createElement("svg", Object.assign({ "data-testid": props["data-testid"], xmlns: "http://www.w3.org/2000/svg", width: "17", height: "16", viewBox: "0 0 17 16" }, props),
    React__default.createElement("title", null, "Check icon"),
    React__default.createElement("g", { fill: "none", fillRule: "evenodd" },
        React__default.createElement("path", { d: "M0 0h16v16H0z" }),
        React__default.createElement("path", { fill: "#56BF98", d: "M3.733 6.55a1 1 0 1 0-1.466 1.361l3.5 3.77a1 1 0 0 0 1.466 0l6.5-7a1 1 0 1 0-1.466-1.361L6.5 9.53 3.733 6.55z" }))));
CheckIcon.defaultProps = {
    "data-testid": "arrow-right-icon",
};

export { CheckIcon };
