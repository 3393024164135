import React__default from 'react';

const Confetti3Rows = (props) => (React__default.createElement("svg", Object.assign({ xmlns: "http://www.w3.org/2000/svg", width: "202", height: "55", viewBox: "0 0 202 55" }, props),
    React__default.createElement("title", null, "Confetti pattern"),
    React__default.createElement("g", { fill: "#56BF98", fillRule: "evenodd" },
        React__default.createElement("path", { d: "M0 67.626l9.76 6.769V77L0 70.25v-2.624zm47.53 0l9.76 6.769V77l-9.76-6.75v-2.624zm24.188-.852l9.761 6.769v2.605l-9.76-6.75v-2.624zm23.765 0l9.76 6.769v2.605l-9.76-6.75v-2.624zm24.19 0l9.76 6.769v2.605l-9.76-6.75v-2.624zm23.764 0l9.76 6.769v2.605l-9.76-6.75v-2.624zm48.802 0L202 73.543v2.605l-9.76-6.75v-2.624zM30.342 71.248v2.982h-2.97v-2.982h2.97zm-6.577-26.205l9.76 6.77v2.604l-9.76-6.749v-2.625zM0 45.043l9.76 6.77v2.604L0 47.668v-2.625zm47.53 0l9.76 6.77v2.604l-9.76-6.749v-2.625zm47.953 0l9.76 6.77v2.604l-9.76-6.749v-2.625zm24.19 0l9.76 6.77v2.604l-9.76-6.749v-2.625zm47.953 0l9.76 6.77v2.604l-9.76-6.749v-2.625zm24.613 0l9.761 6.77v2.604l-9.76-6.749v-2.625zm-43.497 2.77v2.983h-2.971v-2.983h2.97zM71.718 22.887l9.761 6.769v2.605l-9.76-6.75v-2.624zm47.954 0l9.76 6.769v2.605l-9.76-6.75v-2.624zm23.765 0l9.76 6.769v2.605l-9.76-6.75v-2.624zm-95.908 0l9.76 6.769v2.605l-9.76-6.75v-2.624zm144.71 0L202 29.656v2.605l-9.76-6.75v-2.624zm-168.474 0l9.76 6.769v2.605l-9.76-6.75v-2.624zm143.861 0l9.76 6.769v2.605l-9.76-6.75v-2.624zm-72.143-.426l9.76 6.769v2.605l-9.76-6.75v-2.624zM23.765 1.157l9.76 6.768v2.605l-9.76-6.749V1.157zM0 .73L9.76 7.5v2.604L0 3.355V.73zm71.718 0L81.48 7.5v2.604l-9.76-6.749V.73zm71.719 0l9.76 6.77v2.604l-9.76-6.749V.73zm-25.038 0l9.76 6.77v2.604l-9.76-6.749V.73zm-64.716 4.9v2.983h-2.97V5.63h2.97zm120.945-1.704V6.91h-2.97V3.926h2.97zM0-21l9.76 6.769v2.605L0-18.376V-21zm23.765 0l9.76 6.769v2.605l-9.76-6.75V-21zm23.764 0l9.76 6.769v2.605l-9.76-6.75V-21zm24.19 0l9.76 6.769v2.605l-9.76-6.75V-21zm23.764 0l9.76 6.769v2.605l-9.76-6.75V-21zm24.19 0l9.76 6.769v2.605l-9.76-6.75V-21zm47.953 0l9.76 6.769v2.605l-9.76-6.75V-21zm24.613 0L202-14.231v2.605l-9.76-6.75V-21z" }),
        React__default.createElement("path", { d: "M167.626 66.64l9.76 6.759V76l-9.76-6.74v-2.62zM71.718 44.943l9.761 6.758v2.602l-9.76-6.74v-2.62zM0 22.395l9.76 6.758v2.601L0 25.015v-2.62zM95.483.697l9.76 6.759v2.601l-9.76-6.74V.698zm96.756 0L202 7.456v2.601l-9.76-6.74V.698zM143.437-21l9.76 6.758v2.602l-9.76-6.74V-21z", opacity: ".297" }))));
Confetti3Rows.defaultProps = {
    "data-testid": "confetti-3-rows",
};

export { Confetti3Rows };
