import { useState, useCallback } from 'react';
import axios from 'axios';

const FORM_API_GATEWAY = "https://yclmym5k30.execute-api.us-east-1.amazonaws.com/default/ContactFormAPI-FormHandlerLambda75F0B75F-105H3Q1U7XCQJ";
var FormStatus;
(function (FormStatus) {
    FormStatus[FormStatus["INITIAL"] = 0] = "INITIAL";
    FormStatus[FormStatus["LOADING"] = 1] = "LOADING";
    FormStatus[FormStatus["SUCCESS"] = 2] = "SUCCESS";
    FormStatus[FormStatus["ERROR"] = 3] = "ERROR";
})(FormStatus || (FormStatus = {}));
const useContactFormLogic = (analyticsEvent, senderType, extraData) => {
    const [status, setStatus] = useState(FormStatus.INITIAL);
    const [formValues, setFormValues] = useState({});
    const onChange = useCallback((e) => {
        e.persist();
        if (e.target && e.target.name && e.target.value) {
            setFormValues((current) => (Object.assign(Object.assign({}, current), { [e.target.name]: e.target.value })));
        }
    }, []);
    const onSubmit = useCallback(() => {
        setStatus(FormStatus.LOADING);
        const body = Object.assign(Object.assign(Object.assign({}, formValues), extraData), { sender: senderType });
        // if (process.env.NODE_ENV === 'development') {
        //     alert(JSON.stringify(body, null, 1))
        //     setStatus(FormStatus.INITIAL)
        //     return false
        // }
        axios
            .post(FORM_API_GATEWAY, body)
            .then((response) => {
            if (response.status === 200) {
                setStatus(FormStatus.SUCCESS);
                if (analyticsEvent) {
                    const analyticsData = {
                        event: analyticsEvent,
                        data: formValues,
                    };
                    const dataLayer = window["dataLayer"];
                    if (dataLayer) {
                        dataLayer.push(analyticsData);
                    }
                    else {
                        console.log("analytics data payload", analyticsData);
                    }
                }
            }
            else {
                console.error(`Contact form bad response code: ${response.status}`);
                setStatus(FormStatus.ERROR);
            }
        })
            .catch((err) => {
            console.error("Contact form critical error", err);
            setStatus(FormStatus.ERROR);
        });
        return false;
    }, [formValues, analyticsEvent, senderType, extraData]);
    return {
        status,
        formValues,
        onChange,
        onSubmit,
    };
};

export { FormStatus as F, useContactFormLogic as u };
