import React__default from 'react';
import styled from 'styled-components';
import { G as GREY03 } from '../../colors-c52fac99.js';

const ArrowIcon = (props) => (React__default.createElement(SVG, Object.assign({ viewBox: "0 0 14 10" }, props),
    React__default.createElement("g", { stroke: "null", fill: "null", fillRule: "evenodd" },
        React__default.createElement("path", { stroke: props.stroke, fill: props.fill, d: "m-0.062499,1.571126l14.142,0l-7.07,7.071l-7.072,-7.071z" }))));
ArrowIcon.defaultProps = {
    width: 14,
    height: 20,
    fill: GREY03,
    stroke: "null",
};
const SVG = styled.svg ``;

export default ArrowIcon;
