import { createElement } from 'react';

const LinkedInIcon = (props) => (createElement("svg", Object.assign({ xmlns: "http://www.w3.org/2000/svg", width: "24", height: "24", viewBox: "0 0 24 24" }, props),
    createElement("title", null, "LinkedIn icon"),
    createElement("g", { fill: "none", fillRule: "nonzero" },
        createElement("path", { fill: "#A4AAA8", d: "M22.225 0H1.77C.794 0 0 .775 0 1.73v20.538C0 23.224.794 24 1.77 24h20.455c.978 0 1.775-.776 1.775-1.732V1.73C24 .775 23.203 0 22.225 0z" }),
        createElement("path", { fill: "#FFF", d: "M3.722 9.12H7.42v11.451h-3.7V9.121zm1.85-5.691c1.183 0 2.142.924 2.142 2.063 0 1.14-.96 2.064-2.142 2.064-1.186 0-2.143-.924-2.143-2.064 0-1.139.957-2.063 2.143-2.063zM9.429 8.862h3.425v1.6h.05c.476-.92 1.642-1.89 3.38-1.89 3.619 0 4.287 2.424 4.287 5.578v6.421h-3.573v-5.694c0-1.358-.023-3.105-1.857-3.105-1.86 0-2.143 1.48-2.143 3.008v5.791h-3.57V8.862z" }))));
LinkedInIcon.defaultProps = {
    "data-testid": "linkedin-icon",
};

export default LinkedInIcon;
export { LinkedInIcon };
