import { createElement } from 'react';

const StopwatchIcon = (props) => (createElement("svg", Object.assign({ xmlns: "http://www.w3.org/2000/svg", width: "100", height: "100", viewBox: "0 0 100 100" }, props),
    createElement("title", null, "Stopwatch icon"),
    createElement("g", { fill: "none", fillRule: "evenodd" },
        createElement("path", { d: "M0 0h100v100H0z" }),
        createElement("path", { fill: "#BAE1D3", d: "M94 49.911C94 25.66 74.3 6 50 6v43.911L75.056 86C86.501 78.071 94 64.869 94 49.911" }),
        createElement("path", { fill: "#323433", fillRule: "nonzero", d: "M11 50c0 21.539 17.461 39 39 39 21.54 0 39-17.46 39-39S71.54 11 50 11c-21.539 0-39 17.461-39 39zm2 0c0-20.434 16.566-37 37-37 20.435 0 37 16.565 37 37S70.435 87 50 87c-20.434 0-37-16.566-37-37z" }),
        createElement("path", { fill: "#323433", fillRule: "nonzero", d: "M32.666 24.172A31.05 31.05 0 0 0 19 49.932C19 67.09 32.878 81 50 81v-2c-16.016 0-29-13.013-29-29.068a29.05 29.05 0 0 1 12.787-24.104l-1.121-1.656zM65.971 76.856A31.162 31.162 0 0 0 81 50.15C81 32.948 67.122 19 50 19v2c16.015 0 29 13.05 29 29.151a29.162 29.162 0 0 1-14.064 24.993l1.035 1.712z" }),
        createElement("path", { fill: "#FFF", d: "M44 50a6 6 0 1 0 12.001 0A6 6 0 0 0 44 50" }),
        createElement("path", { fill: "#323433", fillRule: "nonzero", d: "M43 50a7 7 0 1 0 14.001 0A7 7 0 0 0 43 50zm2 0a5 5 0 1 1 10 0 5 5 0 0 1-10 0z" }),
        createElement("path", { fill: "#323433", fillRule: "nonzero", d: "M52.168 55.555l10 15 1.664-1.11-10-15z" }))));
StopwatchIcon.defaultProps = {
    "data-testid": "icon-stopwatch-small",
};

export default StopwatchIcon;
export { StopwatchIcon };
