import React__default from 'react';

const Confetti2x4 = (props) => (React__default.createElement("svg", Object.assign({ xmlns: "http://www.w3.org/2000/svg", width: "78", height: "33.103", viewBox: "0 0 78 33.1034483", style: { transform: "scaleX(-1)" } }, props),
    React__default.createElement("title", null, "Confetti pattern"),
    React__default.createElement("g", { fill: "#56BF98", fillRule: "evenodd" },
        React__default.createElement("path", { d: "M55.374 22.44v2.71l-9.229 6.97v-2.69l9.229-6.99zm22.47 0v2.71l-9.228 6.97v-2.69l9.229-6.99zm-68.615 0v2.71L0 32.12v-2.69l9.229-6.99zM32.502 0v2.71l-9.229 6.97V6.99L32.503 0zM9.23 0v2.71L0 9.68V6.99L9.229 0zm68.616 0v2.71l-9.23 6.97V6.99L77.846 0zM50.358 2.86h2.809v3.08h-2.809V2.86z" }),
        React__default.createElement("path", { d: "M32.502 22.733v2.719l-9.229 6.992v-2.699z", opacity: ".297" }))));
Confetti2x4.defaultProps = {
    "data-testid": "confetti-micro",
};

export { Confetti2x4 };
