import React__default from 'react';

const Confetti3x5 = (props) => (React__default.createElement("svg", Object.assign({ xmlns: "http://www.w3.org/2000/svg", width: "108", height: "53", viewBox: "0 0 108 53" }, props),
    React__default.createElement("title", null, "Confetti pattern"),
    React__default.createElement("g", { fill: "#56BF98", fillRule: "evenodd" },
        React__default.createElement("path", { d: "M.5.5l10 6.499V9.5L.5 3.02z" }),
        React__default.createElement("path", { d: "M.5 22l10 6.499V31l-10-6.48z", opacity: ".297" }),
        React__default.createElement("path", { d: "M.5 43.5l10 6.499V52.5l-10-6.48zM24.5.5l10 6.499V9.5l-10-6.48z" }),
        React__default.createElement("path", { d: "M48.5.5l10 6.499V9.5l-10-6.48z", opacity: ".297" }),
        React__default.createElement("path", { d: "M73 .5l10 6.499V9.5L73 3.02zM48.5 22l10 6.499V31l-10-6.48zM23.5 22l10 6.499V31l-10-6.48zM24.5 44l10 6.499V53l-10-6.48zM48.5 44l10 6.499V53l-10-6.48zM73 44l10 6.499V53l-10-6.48zM77 25h3v3h-3zM98 .5l10 6.499V9.5L98 3.02z" }),
        React__default.createElement("path", { d: "M98 22l10 6.499V31l-10-6.48z", opacity: ".314" }),
        React__default.createElement("path", { d: "M98 44l10 6.499V53l-10-6.48z" }))));
Confetti3x5.defaultProps = {
    "data-testid": "confetti-small",
};

export { Confetti3x5 };
