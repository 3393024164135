import { createElement } from 'react';

const PenIcon = (props) => (createElement("svg", Object.assign({ xmlns: "http://www.w3.org/2000/svg", width: "64", height: "64", viewBox: "0 0 64 64" }, props),
    createElement("title", null, "Pen icon"),
    createElement("g", { fill: "none", fillRule: "evenodd" },
        createElement("path", { d: "M0 0h64v64H0z" }),
        createElement("g", { transform: "translate(7 5)" },
            createElement("path", { fill: "#3C3F3E", fillRule: "nonzero", d: "M30.392 49.313L1.196 53.804l4.491-29.196 15.121-5.672-.702-1.872-16.217 6.083-5.085 33.049 33.05-5.085 6.082-16.217-1.872-.702z" }),
            createElement("path", { fill: "#3C3F3E", fillRule: "nonzero", d: "M.707 55.707l16-16-1.414-1.414-16 16z" }),
            createElement("path", { fill: "#BAE1D3", d: "M13.95 11.35L24.558.744l24.75 24.748L38.7 36.1z" }),
            createElement("path", { fill: "#3C3F3E", fillRule: "nonzero", d: "M15.386 14.2l8.615-8.615 25.414 25.414-8.615 8.614L15.386 14.2zm2.829 0L40.8 36.785l5.786-5.786L24.001 8.413 18.215 14.2z" }),
            createElement("circle", { cx: "19.2", cy: "35.8", r: "4.8", fill: "#BAE1D3", transform: "rotate(-45 19.2 35.8)" }),
            createElement("path", { fill: "#3C3F3E", fillRule: "nonzero", d: "M23.3 39.901a5.8 5.8 0 1 0-8.202-8.202 5.8 5.8 0 0 0 8.203 8.202zm-1.414-1.414a3.8 3.8 0 1 1-5.373-5.374 3.8 3.8 0 0 1 5.373 5.374z" })))));
PenIcon.defaultProps = {
    "data-testid": "icon-pen-small",
};

export default PenIcon;
export { PenIcon };
