const spacing = {
    mobile: {
        padding: {
            default: 24,
        },
        betweenSections: 80,
    },
    desktop: {
        maxContentWidth: 1138,
        betweenSections: 180,
    },
    maxWidth: 1640,
};

export { spacing as s };
