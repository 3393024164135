import React__default from 'react';

const ArrowRightIcon = (props) => (React__default.createElement("svg", Object.assign({ "data-testid": props["data-testid"], xmlns: "http://www.w3.org/2000/svg", width: "24", height: "24", viewBox: "0 0 24 24" }, props),
    React__default.createElement("title", null, "Arrow icon"),
    React__default.createElement("g", { fill: "#A4AAA8", fillRule: "nonzero" },
        React__default.createElement("path", { d: "M4.5 13a.5.5 0 1 1 0-1h15a.5.5 0 1 1 0 1h-15z" }),
        React__default.createElement("path", { d: "M14.146 7.854a.5.5 0 0 1 .708-.708l5 5a.5.5 0 0 1 0 .708l-5 5a.5.5 0 0 1-.708-.708l4.647-4.646-4.647-4.646z" }))));
ArrowRightIcon.defaultProps = {
    "data-testid": "arrow-right-icon",
};

export { ArrowRightIcon };
