import React__default from 'react';

const SortIcon = (props) => (React__default.createElement("svg", { width: props.width, height: props.height, viewBox: "0 0 14 20", version: "1.1" },
    React__default.createElement("g", { fill: "none", fillRule: "evenodd" },
        React__default.createElement("path", { fill: "#B0B6B8", d: "M0 13.071h14.142l-7.07 7.071z" }),
        React__default.createElement("path", { fill: "#56BF98", d: "M14.142 7.071H0L7.071 0z" }))));
SortIcon.defaultProps = {
    width: 14,
    height: 20,
};

export default SortIcon;
