import React__default from 'react';

const Confetti4Rows = (props) => {
    const passedProps = Object.assign({}, props);
    //@ts-ignore
    delete passedProps.flipConfetti;
    return (React__default.createElement("svg", Object.assign({ xmlns: "http://www.w3.org/2000/svg", width: "171", height: "195", viewBox: "0 0 171 195" }, passedProps),
        React__default.createElement("g", { fill: "#56BF98", fillRule: "evenodd", transform: "matrix(-1 0 0 1 171 0)" },
            React__default.createElement("polygon", { points: "3 19 15.998 -1 21 -1 8.04 19", transform: "rotate(90 12 9)" }),
            React__default.createElement("polygon", { points: "52 19 64.998 -1 70 -1 57.04 19", opacity: ".297", transform: "rotate(90 61 9)" }),
            React__default.createElement("polygon", { points: "101 19 113.998 -1 119 -1 106.04 19", transform: "rotate(90 110 9)" }),
            React__default.createElement("polygon", { points: "52 63 64.998 43 70 43 57.04 63", transform: "rotate(90 61 53)" }),
            React__default.createElement("polygon", { points: "1 63 13.998 43 19 43 6.04 63", transform: "rotate(90 10 53)" }),
            React__default.createElement("polygon", { points: "3 107 15.998 87 21 87 8.04 107", transform: "rotate(90 12 97)" }),
            React__default.createElement("polygon", { points: "52 107 64.998 87 70 87 57.04 107", transform: "rotate(90 61 97)" }),
            React__default.createElement("polygon", { points: "101 107 113.998 87 119 87 106.04 107", transform: "rotate(90 110 97)" }),
            React__default.createElement("rect", { width: "6", height: "6", x: "108", y: "50" }),
            React__default.createElement("rect", { width: "6", height: "6", x: "56", y: "138" }),
            React__default.createElement("polygon", { points: "3 151 15.998 131 21 131 8.04 151", transform: "rotate(90 12 141)" }),
            React__default.createElement("polygon", { points: "3 196 15.998 176 21 176 8.04 196", transform: "rotate(90 12 186)" }),
            React__default.createElement("polygon", { points: "52 196 64.998 176 70 176 57.04 196", transform: "rotate(90 61 186)" }),
            React__default.createElement("polygon", { points: "101 151 113.998 131 119 131 106.04 151", transform: "rotate(90 110 141)" }),
            React__default.createElement("polygon", { points: "101 196 113.998 176 119 176 106.04 196", opacity: ".314", transform: "rotate(90 110 186)" }),
            React__default.createElement("polygon", { points: "152 19 164.998 -1 170 -1 157.04 19", transform: "rotate(90 161 9)" }),
            React__default.createElement("polygon", { points: "152 63 164.998 43 170 43 157.04 63", opacity: ".314", transform: "rotate(90 161 53)" }),
            React__default.createElement("polygon", { points: "152 107 164.998 87 170 87 157.04 107", transform: "rotate(90 161 97)" }),
            React__default.createElement("polygon", { points: "152 151 164.998 131 170 131 157.04 151", transform: "rotate(90 161 141)" }),
            React__default.createElement("polygon", { points: "152 196 164.998 176 170 176 157.04 196", transform: "rotate(90 161 186)" }))));
};
Confetti4Rows.defaultProps = {
    "data-testid": "confetti-4-rows",
};

export { Confetti4Rows };
